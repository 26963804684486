import React from "react";
import "./switch.scss";

const Switch = ({ size = "sm", id, name, onChange, disabled, checked }) => (
  <>
    <input className="switch-checkbox" id={id} name={name} type="checkbox" onChange={onChange} disabled={disabled} checked={checked} />
    <label
      title={checked ? "Enabled" : "Disabled"}
      aria-labelledby={checked ? "Enabled" : "Disabled"}
      aria-describedby={checked ? "Active switch to show that this instance is enabled" : "Inactive switch to show that this instance is enabled"}
      className={`switch-label ${size ? size : ""} ${checked ? "checked" : ""} ${disabled ? "disabled" : ""}`}
      htmlFor={id}
    >
      <span className={`switch-button ${size ? size : ""}`} />
    </label>
  </>
);

export default Switch;
