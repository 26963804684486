import React, { useEffect, useMemo, useState } from "react";
// import { Line } from "react-chartjs-2";

import {
  Typography,
  Lozenge,
  Button,
  PaginationWrapper,
  Pagination,
  PaginationIndicator,
  Table,
} from "vgg-ui-react/dist/components/Core";

// import { Elipses } from "components/svgs";
// import AccountsModal from "components/accountsModal/AccountsModal";
import EmptyState from "components/emptyState/EmptyState";
import { LineLoader, LoadingTableContent } from "components/loader/Loader";

import { useDispatch, useSelector } from "react-redux";
import { LoadAccounts, LoadWalletSummary } from "redux/actions/walletInstance";
import { getCardType } from "helpers/switchCardType";
import { getCurrencyValue } from "helpers/currencyMarkup";
import { useHistory, useParams } from "react-router";
import URLS, { ConfigureUpdate } from "helpers/urls";
import "./walletConfiguration.scss";
import Initials from "components/initials/initials";

const WalletConfiguration = ({ isChild }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const {
    loadingWalletHistory,
    walletHistory,
    loadingAccounts,
    accounts,
    walletSummary,
    loadingWalletSummary,
  } = useSelector(({ walletInstances }) => walletInstances);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [pagedData, setPageData] = useState([]);
  const [modal, setModal] = useState(false);
  const instance = useMemo(
    () => history?.location?.state?.data,
    [history?.location?.state?.data]
  );

  const toggle = () => setModal(!modal);
  useEffect(() => {
    dispatch(LoadWalletSummary({ instanceId: params?.id }));
    dispatch(LoadAccounts({ instanceId: params?.id }));
    // dispatch(LoadChartData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingWalletHistory && walletHistory?.length) {
      const firstPageIndex = (currentPage - 1) * pageSize;
      const lastPageIndex = firstPageIndex + pageSize;
      setPageData(walletHistory.slice(firstPageIndex, lastPageIndex));
    }
  }, [currentPage, pageSize, loadingWalletHistory, walletHistory]);

  const onEditConfiguration = (event, rowData) => {
    const { id } = params;
    history.push({
      pathname: ConfigureUpdate(id),
      state: { data: history?.location?.state?.data },
    });
  };

  return (
    <div className="wallet-configuration">
      <div
        className="pg-header d-flex align-items-center justify-content-between sp-pb--32"
        style={{ borderBottom: "1px solid #c3c3c3" }}
      >
        <div className="d-flex align-items-center">
          <span
            className="back-btn"
            onClick={() =>
              history.push({
                pathname: `${isChild ? URLS.WalletService : "/"}`,
                state: { actionType: "configure" },
              })
            }
          >
            <i className="ri-arrow-left-s-line ri-2x"></i>
          </span>
          <Typography
            typographyVariant="disp-heading"
            text="Wallet Configuration"
            className="font-weight-bold"
          />
        </div>
        <div style={{ width: 150 }}>
          <Button
            label="Edit"
            background="blue"
            size="sm"
            style={{ width: "100%" }}
            onClick={onEditConfiguration}
          />
        </div>
      </div>
      <div className="content-container">
        <div className="content-left sp-pt--48">
          <Initials fullName={instance?.name} />
          <div className="content-left sp-pt--32">
            <Typography
              typographyVariant="subtitle1"
              text={instance?.name}
              className="font-weight-bold"
            />
          </div>
          <div className="content-left sp-pt--16">
            {/* <Lozenge content="Enabled" style={{ background: "rgba(65, 201, 119, 0.3)", color: "#207040" }} /> */}

            {instance && (
              <Lozenge
                content={instance.status ? "Enabled" : "Disabled"}
                variant={instance.status ? "success" : "failed"}
              />
            )}
          </div>
          <div className="content-left sp-pt--16">
            <Typography
              typographyVariant="caption"
              text="--"
              className="sp-text-color--neutral-400"
            />
          </div>
          {/* <div className="content-left sp-pt--24">
            <Button
              label={
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <i className="ri-user-add-line"></i>
                    <span className="ms-2">Accounts</span>
                  </div>
                  <div>
                    <i className="ri-arrow-right-s-line"></i>
                  </div>
                </div>
              }
              background="neutral"
              size="sm"
              className="me-2"
              style={{
                background: "#eee",
                color: "#7E7E7E",
                width: "200px",
                border: "1px solid #E1E1E1",
              }}
              onClick={toggle}
            />
          </div> */}
          <div className="content-left sp-pt--48">
            <ul className="left-list">
              <li>
                <a
                  href="https://www.providusbank.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="ri-global-line"></i>&nbsp; Callback URL
                </a>
                <p>www.providus.com</p>
              </li>
              <li>
                <a
                  // onClick={() => history.push("/wallet")}
                  href="/wallet"
                  rel="noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  <i className="ri-global-line"></i>&nbsp; Wallet Type
                </a>
                <p>{instance?.walletType || "--"}</p>
              </li>
              <li>
                <a
                  href="https://www.africaone.io/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="ri-window-line"></i>&nbsp; Application Type
                </a>
                <p>Web Application</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="content-right p-3">
          <div className="pg-header sp-pt--48">
            <Typography
              typographyVariant="section-title"
              text="General Information"
              className="font-weight-bold"
            />
          </div>
          <div className="sp-pt--48">
            <div className="summary">
              <div className="wallet">
                <div className="d-flex align-items-center justify-content-between">
                  <Typography
                    typographyVariant="caption"
                    text="Wallet Value"
                    className="font-weight-bold"
                  />
                  <div className="icon d-flex justify-content-center align-items-center">
                    <i className="ri-money-dollar-circle-line"></i>
                  </div>
                </div>
                <Typography
                  typographyVariant="disp-heading"
                  text={
                    loadingWalletSummary ? (
                      <div style={{ width: "25%" }}>
                        <LineLoader />
                      </div>
                    ) : (
                      getCurrencyValue(
                        instance?.currency.toLowerCase(),
                        walletSummary?.walletValue
                      )
                    )
                  }
                  className="font-weight-bold"
                />
              </div>
              <div className="users">
                <div className="d-flex align-items-center justify-content-between">
                  <Typography
                    typographyVariant="caption"
                    text="Total Accounts"
                    className="font-weight-bold"
                  />
                  <div className="icon d-flex justify-content-center align-items-center">
                    <i className="ri-user-line"></i>
                  </div>
                </div>
                <Typography
                  typographyVariant="disp-heading"
                  text={
                    loadingWalletSummary ? (
                      <div style={{ width: "25%" }}>
                        <LineLoader />
                      </div>
                    ) : (
                      walletSummary?.data?.totalWallets || "--"
                    )
                  }
                  className="font-weight-bold"
                />
              </div>
              <div className="currency">
                <div className="d-flex align-items-center justify-content-between">
                  <Typography
                    typographyVariant="caption"
                    text="Currency"
                    className="font-weight-bold"
                  />
                  <div className="icon d-flex justify-content-center align-items-center">
                    <i className="ri-money-dollar-circle-line"></i>
                  </div>
                </div>
                <Typography
                  typographyVariant="disp-heading"
                  text={
                    loadingWalletSummary ? (
                      <div style={{ width: "25%" }}>
                        <LineLoader />
                      </div>
                    ) : (
                      walletSummary?.data?.currency || "--"
                    )
                  }
                  className="font-weight-bold"
                />
              </div>
            </div>
          </div>
          {/* <div className="sp-pt--48">
            <div className="d-flex align-items-center justify-content-between">
              <Typography typographyVariant="section-title" text="Requests by API" className="font-weight-bold" />
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center me-5">
                  {Elipses("#175FFF")}&nbsp;&nbsp;
                  <Typography typographyVariant="caption" text="Credited Transaction" />
                </div>
                <div className="d-flex align-items-center">
                  {Elipses("#FC4646")}&nbsp;&nbsp;
                  <Typography typographyVariant="caption" text="Debited Transaction" />
                </div>
              </div>
            </div>
          </div> */}
          <div className="sp-pt--48">
            <div className="d-flex align-items-center justify-content-between">
              <Typography
                typographyVariant="section-title"
                text="Accounts"
                className="font-weight-bold"
              />
              {/* <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center me-5">
                  {Elipses("#175FFF")}&nbsp;&nbsp;
                  <Typography typographyVariant="caption" text="Credited Transaction" />
                </div>
                <div className="d-flex align-items-center">
                  {Elipses("#FC4646")}&nbsp;&nbsp;
                  <Typography typographyVariant="caption" text="Debited Transaction" />
                </div>
              </div> */}
            </div>
          </div>
          <div className="sp-pt--24">
            {/* <Line
              data={walletChart}
              height={420}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            /> */}
            {loadingAccounts ? (
              <Table variant="">
                <thead>
                  <tr>
                    <th width="15%">Acc Name</th>
                    <th width="25%">Acc Number</th>
                    <th width="25%">Available Balance</th>
                    <th width="15%">Ledger Balance</th>
                    <th width="15%">KYC Level</th>
                  </tr>
                </thead>
                <tbody style={{ borderTop: "none" }}>
                  <LoadingTableContent colSpan={5} />
                </tbody>
              </Table>
            ) : (
              <>
                {accounts.data && accounts.data.length ? (
                  <>
                    <Table variant="unBounded">
                      <thead>
                        <tr>
                          <th width="15%">Acc Name</th>
                          <th width="25%">Acc Number</th>
                          <th width="25%">Available Balance</th>
                          <th width="15%">Ledger Balance</th>
                          <th width="15%">KYC Level</th>
                        </tr>
                      </thead>
                      <tbody style={{ borderTop: "none" }}>
                        {accounts?.data?.map((app, idx) => (
                          <tr key={idx}>
                            <td>
                              <span>{app?.accountName || "--"}</span>
                            </td>
                            <td>
                              <span>{app?.accountNumber || "--"}</span>
                            </td>
                            <td>
                              {getCurrencyValue(
                                app?.currency,
                                app?.availableBalance
                              )}
                            </td>
                            <td>
                              {getCurrencyValue(
                                app?.currency,
                                app?.ledgerBalance
                              )}
                            </td>
                            <td>{app?.kycLevel || "--"}</td>
                          </tr>
                        ))}{" "}
                      </tbody>
                    </Table>
                    <div>
                      <PaginationWrapper>
                        <PaginationIndicator
                          currentPage={accounts?.pageNumber || 1}
                          totalPages={accounts?.pages || 1}
                        />
                        <div className="pagination-container">
                          <Pagination
                            className="pagination-bar"
                            currentPage={accounts?.pageNumber || 1}
                            totalCount={accounts?.count || 1}
                            pageSize={accounts?.pageSize || 1}
                            onPageChange={(page) => setCurrentPage(page)}
                          />
                        </div>
                      </PaginationWrapper>
                    </div>
                  </>
                ) : (
                  <EmptyState description="No account has been created for this instance" />
                )}
              </>
            )}
          </div>
          <div className="sp-pt--48">
            <div className="sp-pb--24">
              <Typography
                typographyVariant="section-title"
                text="Wallet History"
                className="font-weight-bold"
              />
            </div>

            {loadingWalletHistory ? (
              <Table variant="">
                <thead>
                  <tr>
                    <th width="15%">Transaction</th>
                    <th width="25%">Card</th>
                    <th width="25%">Amount</th>
                    <th width="15%">Date</th>
                    <th width="15%">Time</th>
                  </tr>
                </thead>
                <tbody style={{ borderTop: "none" }}>
                  <LoadingTableContent colSpan={5} />
                </tbody>
              </Table>
            ) : (
              <>
                {walletHistory.length ? (
                  <>
                    <Table variant="unBounded">
                      <thead>
                        <tr>
                          <th width="15%">Transaction</th>
                          <th width="25%">Card</th>
                          <th width="25%">Amount</th>
                          <th width="15%">Date</th>
                          <th width="15%">Time</th>
                        </tr>
                      </thead>
                      <tbody style={{ borderTop: "none" }}>
                        {pagedData.slice(0, 5).map((app, idx) => (
                          <tr key={app.id}>
                            <td>
                              <div className="d-flex align-items-center w-100 h-100">
                                <span
                                  className={` ${
                                    app.transaction.toLowerCase() === "credited"
                                      ? "ri-arrow-right-down-line credited"
                                      : "ri-arrow-left-up-line debited"
                                  } me-2`}
                                ></span>

                                <span>{app.transaction}</span>
                              </div>
                            </td>
                            <td>
                              <span className="me-2">
                                {getCardType(app.cardType)}{" "}
                              </span>
                              <span>{app.card}</span>
                            </td>
                            <td>{app.amount}</td>
                            <td>{app.date}</td>
                            <td>{app.time}</td>
                          </tr>
                        ))}{" "}
                      </tbody>
                    </Table>
                    <div>
                      <PaginationWrapper>
                        <PaginationIndicator
                          currentPage={currentPage}
                          totalPages={Math.ceil(
                            walletHistory.length / pageSize
                          )}
                        />
                        <div className="pagination-container">
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={walletHistory.length}
                            pageSize={pageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                          />
                        </div>
                      </PaginationWrapper>
                    </div>
                  </>
                ) : (
                  <EmptyState />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* <AccountsModal loading={loadingAccounts} accounts={accounts} toggle={toggle} modal={modal} instanceId={params?.id} /> */}
    </div>
  );
};

export default WalletConfiguration;
