import React from "react";
import { Typography } from "vgg-ui-react/dist/components/Core";

const GeneralInfoTab = () => (
  <div className="row" style={{ height: "60vh", overflowY: "auto" }}>
    <div className="col-6">
      <Typography typographyVariant="section-title" text="Overview" className="pg-title" />
      <div className="sp-pt--8">
        <p>We allow companies to create wallets and accounts for their users. We also provide account management and borderless transactions on the wallets.</p>
      </div>
      {/* <div className="sp-mt--48">
        <div className="sp-pt-24">
          <Typography typographyVariant="section-title" text="Pricing" className="pg-title" />
          <p>NOTE : Usage fee is billed every month</p>
          <div className="pricing">
            <div className="row-one">
              <div className="left"></div>
              <div className="sp-pt--24 right d-flex flex-column justify-content-center align-items-center">
                <div className="sp-pt--16 sp-mb--24">
                  <Typography typographyVariant="disp-heading" text="$200.86/month" className="pg-title" />
                  <div className="sp-pt--24 sp-mb-32 text-center">
                    <Button label="Select" background="blue" size="lg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row-one">
              <div className="left py-3">
                <span className="ml-4">Card control</span>
              </div>
              <div className="right d-flex flex-column justify-content-center align-items-center py-3">
                <span>120/count</span>
              </div>
            </div>
            <div className="row-one">
              <div className="left  py-2">
                <span className="ml-4">Reimbursement</span>
              </div>
              <div className="right d-flex flex-column justify-content-center align-items-center py-2">
                <span>
                  <i className="ri-check-line" style={{ fontSize: 20 }}></i>
                </span>
              </div>
            </div>
            <div className="row-one" style={{ borderBottom: "none" }}>
              <div className="left  py-2">
                <span className="ml-4">Pre-analytics</span>
              </div>
              <div className="right d-flex flex-column justify-content-center align-items-center  py-2">
                <span>
                  <i className="ri-check-line" style={{ fontSize: 20 }}></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="sp-mt--48">
        <div className="sp-pt-24">
          <Typography typographyVariant="section-title" text="Tutorial" className="pg-title" />

          <div className="video-responsive sp-mt--24">
            <iframe
              width="400"
              height="200"
              src={`https://www.youtube.com/embed/rokGy0huYEA`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        </div>
      </div> */}

      <div className="sp-mt--48">
        <div className="sp-pt-24">
          <Typography typographyVariant="section-title" text="Documentation" className="pg-title" />

          <div className="sp-pt--8">
            <ul>
              <li className="mb-3">
                <a href="https://developer.africaone.io" rel="noreferrer" target="_blank">
                  Getting Started right here &nbsp;<i className="ri-external-link-line"></i>
                </a>
              </li>
              <li>
                <a href="https://developer.africaone.io" rel="noreferrer" target="_blank">
                  How to get your API keys &nbsp;<i className="ri-external-link-line"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="col-3"></div>
    <div className="col-3">
      <div className="sp-mb--48">
        <Typography typographyVariant="section-title" text="Additional Information" className="pg-title" />
      </div>
      <div>
        <p>
          <span className="font-weight-bold">Runs on:</span>&nbsp; Kenel Cloud Service
        </p>
        <p>
          <span className="font-weight-bold">Type:</span>&nbsp; <span className="sp-text-color--blue">SaaS and APIs</span>
        </p>
        <p>
          <span className="font-weight-bold">Last Updated:</span>&nbsp; 20/02/2021
        </p>
        <p>
          <span className="font-weight-bold">Category:</span>&nbsp;<span className="sp-text-color--blue">Analytics, Finance</span>
        </p>
      </div>
    </div>
  </div>
);

export default GeneralInfoTab;
