import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { useYupValidationResolver } from "helpers/yupValidationResolver";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import SelectControl from "components/selectControl/SelectControl";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Select from "react-select";
import { useHistory, useParams } from "react-router";
import "./configureWallet.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  ConfigureWalletInstance,
  LoadWalletCurrencies,
} from "redux/actions/walletInstance";
import { successMessage, errorMessage } from "redux/actions/notification";
import { Spinner } from "reactstrap";
import URLS from "helpers/urls";

const walletTypes = [
  { label: "STANDARD", value: "STANDARD" },
  { label: "NUBAN", value: "NUBAN" },
];

const ConfigureWallet = ({ isChild }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [editMode, setEditMode] = useState(false);
  const [sumbitStatus, setSubmitStatus] = useState(false);

  const {
    creatingInstance,
    createInstanceSuccess,
    createInstanceError,
    data,
    currencies,
  } = useSelector(({ walletInstances }) => walletInstances);

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required"),
  });
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
  } = useForm({ resolver });

  const { walletType } = watch();

  useEffect(() => {
    if (walletType?.value === "NUBAN") {
      const { name, currencyCode } =
        currencies?.items?.find((v) => v?.currencyCode === "NGN") || {};
      setValue("currency", { label: name, value: currencyCode });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletType]);

  useEffect(() => {
    if (!creatingInstance && createInstanceSuccess && sumbitStatus) {
      setSubmitStatus(false);
      dispatch(successMessage("Instance configuration successful"));
      history.push({
        pathname: `${isChild ? URLS.WalletService : "/"}`,
        state: { actionType: "configure" },
      });
    }
    if (!creatingInstance && createInstanceError && sumbitStatus) {
      setSubmitStatus(false);
      dispatch(
        errorMessage(
          createInstanceError?.message || "Instance configuration failed"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createInstanceSuccess, creatingInstance]);

  const onSubmit = (data) => {
    setSubmitStatus(true);
    dispatch(
      ConfigureWalletInstance(
        {
          ...data,
          africaOneClientId: undefined,
          walletType: data.walletType.value === "STANDARD" ? 2 : 1,
          status: true,
          currency: data?.currency.value,
        },
        params?.id
      )
    );
  };

  useEffect(() => {
    dispatch(LoadWalletCurrencies());

    return () => {
      reset();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currencies?.items?.length && walletTypes?.length) {
      setInitialValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies, walletTypes]);

  const setInitialValue = () => {
    if (data?.items?.length > 0) {
      if (params?.id) {
        const payload = data.items?.filter(
          (val) => val.instanceId === params.id
        );

        if (payload && payload?.length > 0) {
          const data = payload[0];
          setEditMode(true);
          const fields = [
            "name",
            "africaOneClientId",
            "currency",
            "webhookUrl",
            "status",
            "walletType",
          ];
          fields.forEach((field) => {
            if (field === "walletType") {
              const vals = walletTypes.find((v) => v?.value === data[field]);
              setValue(field, vals);
            } else if (field === "currency") {
              const { name, currencyCode } =
                currencies?.items?.find(
                  (v) => v?.currencyCode === data[field]
                ) || {};
              console.log({ name, currencyCode, field: data[field] });
              setValue(field, { label: name, value: currencyCode });
            } else {
              setValue(field, data[field]);
            }
          });
        }
      }
    }
  };

  return (
    <div className="configure-container">
      <div
        className="pg-header d-flex align-items-center sp-pb--32"
        style={{ borderBottom: "1px solid #c3c3c3" }}
      >
        <span
          className="back-btn"
          onClick={() =>
            history.push({
              pathname: `${isChild ? URLS.WalletService : "/"}`,
              state: { actionType: "configure" },
            })
          }
        >
          <i className="ri-arrow-left-s-line ri-2x"></i>
        </span>
        <Typography
          typographyVariant="disp-heading"
          text="Configure Wallet"
          className="font-weight-bold"
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="container">
        <div className="row sp-pt--48">
          <div className="col-6">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Instance Name
              </label>
              <input
                type="text"
                className={`form-control ${errors?.name ? "is-invalid" : ""}`}
                id="name"
                name="name"
                {...register("name", { required: true })}
              />
              <div className="invalid-feedback">{errors?.name?.message}</div>
            </div>

            {/* <div className="mb-3">
              <label htmlFor="currency" className="form-label">
                Currency
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors?.currency ? "is-invalid" : ""
                }`}
                id="currency"
                name="currency"
                {...register("currency", { required: true })}
              />
              <div className="invalid-feedback">
                {errors?.currency?.message}
              </div>
            </div> */}
            <div className="mb-3">
              <label htmlFor="currency" className="form-label">
                Currency
              </label>
              <div data-testid="configure-controller">
                <Controller
                  control={control}
                  name="currency"
                  id="currency"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      {...register("currency", { required: true })}
                      value={value}
                      onChange={(e) => onChange({ target: { value: e } })}
                      onBlur={onBlur}
                      inputRef={ref}
                      isDisabled={walletType?.value === "NUBAN" || params?.id}
                      controlClassName={`form-control ${
                        errors?.currency ? "is-invalid" : ""
                      } ps-0 pt-0 pb-0`}
                      controlErrorMsg={errors?.currency?.message}
                      components={{
                        IndicatorSeparator: () => null,
                        Control: SelectControl,
                      }}
                      options={
                        currencies?.items?.map((v) => ({
                          label: v?.name,
                          value: v?.currencyCode,
                        })) || []
                      }
                    />
                  )}
                />
                <div className="invalid-feedback">
                  {errors?.currency?.message}
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <label htmlFor="walletType" className="form-label">
                Wallet Type
              </label>
              <Controller
                control={control}
                name="walletType"
                id="walletType"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    {...register("walletType", { required: true })}
                    value={value}
                    onChange={(e) => onChange({ target: { value: e } })}
                    onBlur={onBlur}
                    inputRef={ref}
                    isDisabled={params?.id}
                    controlClassName={`form-control ${
                      errors?.walletType ? "is-invalid" : ""
                    } ps-0 pt-0 pb-0`}
                    controlErrorMsg={errors?.walletType?.message}
                    components={{
                      IndicatorSeparator: () => null,
                      Control: SelectControl,
                    }}
                    defaultValue={value}
                    options={walletTypes}
                  />
                )}
              />
            </div>

            {!params?.id && (
              <div className="mb-3">
                <label htmlFor="webhookUrl" className="form-label">
                  Webhook URL
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors?.webhookUrl ? "is-invalid" : ""
                  }`}
                  id="webhookUrl"
                  name="webhookUrl"
                  {...register("webhookUrl", { required: true })}
                />
                <div className="invalid-feedback">
                  {errors?.webhookUrl?.message}
                </div>
              </div>
            )}
          </div>
          <div className="col-6"></div>
        </div>
        <div className="sp-pt--48 d-flex w-100 align-items-center justify-content-end">
          <div className="actionContainer me-3">
            <Button
              label="Cancel"
              variant="primary"
              type="button"
              className="bg-gray w-100"
              onClick={() =>
                history.push({
                  pathname: `${isChild ? URLS.WalletService : "/"}`,
                  state: { actionType: "configure" },
                })
              }
            />
          </div>
          <div className="actionContainer me-3">
            <Button
              type="submit"
              label={
                <div className="d-flex justify-content-center">
                  <div>
                    {creatingInstance && <Spinner size="sm" color="light" />}
                    <span className="ms-2">
                      {editMode
                        ? `Updat${creatingInstance ? "ing..." : "e"}`
                        : `Creat${creatingInstance ? "ing..." : "e"}`}
                    </span>
                  </div>
                </div>
              }
              background="blue"
              className="w-100"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ConfigureWallet;
