import MasterCard from "assets/images/credit-cards/mastercard.svg";
import Placeholder from "assets/images/credit-cards/placeholder.svg";
import VisaCard from "assets/images/credit-cards/visa.svg";

export function getCardType(issuer) {
  switch (issuer) {
    case "mastercard":
      return <img src={MasterCard} alt="mastercard" width="32" height="32" />;

    case "visa":
      return <img src={VisaCard} alt="visa card" width="32" height="32" />;

    // case "amex":
    //   return "../assets/images/credit-cards/amex.svg";

    // case "dinersclub":
    //   return "../assets/images/credit-cards/dinersclub.svg";

    // case "unionpay":
    //   return "../assets/images/credit-cards/unionpay.svg";

    // case "jcb":
    //   return "../assets/images/credit-cards/jcb.svg";

    // case "hipercard":
    //   return "../assets/images/credit-cards/hipercard.svg";

    // case "discover":
    //   return "../assets/images/credit-cards/discover.svg";

    default:
      return <img src={Placeholder} alt="unknown card" width="32" height="32" />;
  }
}
