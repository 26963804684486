import React from "react";
import { Typography } from "vgg-ui-react/dist/components/Core";

const Initials = ({ fullName, firstName, lastName }) => {
  const getInitialsFromFullName = fn => {
    if (!fn) return "";
    const names = fn.trim().replace(/ +/g, " ").split(" ");

    if (names.length === 1) return fn[0]?.toUpperCase();
    return `${names[0][0].toUpperCase()}${names[1][0]?.toUpperCase()}`;
  };

  return (
    <div className="top-box d-flex align-items-center justify-content-center">
      {<Typography typographyVariant="disp-heading" text={getInitialsFromFullName(fullName || `${firstName} ${lastName}`)} className="font-weight-bold" />}
    </div>
  );
};

export default Initials;
