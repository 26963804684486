import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import URLS from "helpers/urls";

import ServiceDetails from "pages/ServiceDetails/ServiceDetails";
import ConfigureWallet from "pages/ConfigureWallet/ConfigureWallet";
import WalletConfiguration from "pages/WalletConfiguration/WalletConfiguration";
import Notifications from "components/notifications/notifications";
import { useLocation } from "react-router-dom";

function App({ url, user, isChild }) {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
          const paths = url?.split("/wallet-service");
          if (paths?.length > 1) {
            // history.push(paths?.join(''))
          }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className={`container-fluid`}>
      <Notifications />
      <Switch>
        <Route
          exact
          path={URLS.Configure}
          render={() => <ConfigureWallet isChild={isChild} />}
        />
        <Route
          exact
          path={URLS.ConfigureUpdate}
          render={() => <ConfigureWallet isChild={isChild} />}
        />
        <Route
          exact
          path={URLS.Configuration}
          render={() => <WalletConfiguration isChild={isChild} />}
        />
        <Route path={URLS.Home} component={ServiceDetails} />
      </Switch>
    </div>
  );
}

export default App;
