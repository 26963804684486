export const URLS = {
  Home: "/",
  Configure: "/configure",
  ConfigureUpdate: "/configure/:id",
  Configuration: "/configuration/:id",
  WalletService: "/services-wallet",
};

export const ViewConfiguration = (id) => {
  return URLS.Configuration.replace(":id", id);
};

export const ConfigureUpdate = (id) => {
  return URLS.ConfigureUpdate.replace(":id", id);
};

export default URLS;
