import { userDetails } from "./user.service";

export const baseUrl =
  process.env.NODE_ENV === "development"
    ? "https://accountserviceaggregator-apitest.azurewebsites.net/api"
    : `${window.env?.walletServiceBaseApiUrl}/api`;

export const servicemanagerBaseUrl =
  process.env.NODE_ENV === "development"
    ? "https://afservicemanagerclienttest.azurewebsites.net/api"
    : `${window.env?.servicemanagerBaseUrl}api`;

export const config = (customHeader = {}) => {
  if (userDetails()) {
    const bearerToken = `Bearer ${userDetails().accessToken}`;
    return { headers: { Authorization: bearerToken, ...customHeader } };
  }
  return null;
};
