import React, { useEffect, useState } from "react";
import { Typography } from "vgg-ui-react/dist/components/Core";
import Tab from "vgg-ui-react/dist/components/Core/Tab";
import "./service-details.scss";
import GeneralInfoTab from "components/generalInfoTab/GeneralInfoTab";
import WalletInstances from "components/walletInstances/WalletInstances";
import { useHistory } from "react-router-dom";

const ServiceDetails = () => {
  const [activeTab, setActiveTab] = useState("configure");
  const history = useHistory();

  useEffect(() => {
    if (history && history.location) {
      console.log(Date.now(), history.location.state);
      setActiveTab(history.location.state?.actionType);
    }

    return () => {};
  }, [history]);

  return (
    <div className="service-details">
      <section className="head-section d-flex">
        <div className="head-section__icon d-flex justify-content-center align-items-center">
          <i className="ri-bank-card-line"></i>
        </div>
        <div className="head-section__title sp-ml--24">
          <Typography typographyVariant="disp-heading" text="Wallet as a Service" className="pg-title" />
          <div className="sp-pt--16">
            <Typography typographyVariant="caption" text="Services or API" color="neutral-400" className="pg-subtitle" />
          </div>
        </div>
      </section>
      <section className="content-section" data-testid="content-section">
        <Tab
          contents={[
            { title: "General Information", children: <GeneralInfoTab />, id: "view" },
            { title: "Wallet Instances", children: <WalletInstances setActiveTab={setActiveTab} history={history} />, id: "configure" },
          ]}
          bodyStyle={{ paddingTop: 40, marginTop: -2, borderTop: "3px solid #D7DCE0" }}
          titleClassName="tabtitle"
          activeTabClassName="active-tab-className"
          activeTab={activeTab}
        />
      </section>
    </div>
  );
};

export default ServiceDetails;
