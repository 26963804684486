import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";
import "./ToggleStatusModal.scss";

const ToggleStatusModal = ({ show, toggleModal, onSubmit, processing }) => {
  return (
    <div className="deactivate-modal-container">
      <Modal isOpen={!!show} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal} className="font-weight-bold title">
          {show?.status ? "Deactivate" : "Activate"} Instance
        </ModalHeader>
        <ModalBody className="modal__body">
          <p className="mb-0">Are you sure about this, you could lose some information</p>
        </ModalBody>
        <ModalFooter>
          <span data-testid="cancel-btn" onClick={toggleModal} className="px-3 cancel-btn">
            Cancel
          </span>
          <Button color={show?.status ? "danger" : "primary"} onClick={onSubmit} className="submit-btn">
            {show?.status ? "Deactivate" : "Activate"} {processing && <Spinner size="sm" color="light" />}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ToggleStatusModal;
