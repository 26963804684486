import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  PaginationWrapper,
  Pagination,
  PaginationIndicator,
} from "vgg-ui-react/dist/components/Core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "reactstrap";

import TableActionDropdown from "components/tableActionDropdown/tableActionDropdown";
import Switch from "components/switch/Switch";
import { useDispatch, useSelector } from "react-redux";
import _debounce from "lodash.debounce";
// eslint-disable-next-line
import {
  LoadWalletInstances,
  toggleStatus,
} from "redux/actions/walletInstance";
import ToggleStatusModal from "../../components/toggleStatusModal/ToggleStatusModal";
import EmptyState from "components/emptyState/EmptyState";
import { LoadingTableContent } from "components/loader/Loader";
import "./walletInstance.scss";
import { ConfigureUpdate, ViewConfiguration } from "helpers/urls";
import { useHistory } from "react-router-dom";
import { errorMessage, successMessage } from "redux/actions/notification";

const WalletInstances = () => {
  const dispatch = useDispatch();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipId, setTooltipId] = useState(null);

  const history = useHistory();
  const {
    loadingData,
    data,
    switchingStatus,
    switchStatusSuccess,
    switchStatusError,
  } = useSelector(({ walletInstances }) => walletInstances);
  const [dataToSwitch, setDataToSwitch] = useState(null);
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const [sumbitStatus, setSubmitStatus] = useState(false);

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  useEffect(() => {
    dispatch(
      LoadWalletInstances({
        pageNo: paging.pageNumber,
        pageSize: paging.pageSize,
      })
    );
    return () => {
      setDataToSwitch(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  useEffect(() => {
    if (!switchingStatus && switchStatusSuccess && sumbitStatus) {
      dispatch(
        successMessage(
          dataToSwitch?.status
            ? switchStatusSuccess?.message ||
                "Instance deactivated successfully"
            : switchStatusSuccess?.message || "Instance activated successfully"
        )
      );
      setDataToSwitch(null);
      setSubmitStatus(false);
      dispatch(
        LoadWalletInstances({
          pageNo: paging.pageNumber,
          pageSize: paging.pageSize,
        })
      );
    }
    if (!switchingStatus && switchStatusError && sumbitStatus) {
      console.log(switchStatusError);
      setSubmitStatus(false);
      dispatch(
        errorMessage(
          dataToSwitch?.status
            ? switchStatusError?.message || "Instance deactivation failed"
            : switchStatusError?.message || "Instance activation failed"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchingStatus, switchStatusSuccess, switchStatusError]);

  const onEditIconClick = (data) => {
    history.push(ConfigureUpdate(data.instanceId));
    // history.replace({
    //   pathname: ConfigureUpdate(data.instanceId),
    //   state: { data, actionType: "configure" },
    // });
  };

  const onViewConfiguration = (event, rowData) => {
    console.log(rowData);
    history.push({
      pathname: ViewConfiguration(rowData.instanceId),
      state: { data: rowData, actionType: "configure" },
    });
  };

  const handleConfirmSwitch = () => {
    const { instanceId, status } = dataToSwitch;
    dispatch(toggleStatus({ instanceId, activate: !status }));
    setSubmitStatus(true);
  };

  const tableActions = [
    {
      id: 1,
      method: onViewConfiguration,
      name: "View Configuration",
    },
  ];

  const onPageClick = (evt) => {
    setPaging((prev) => ({ ...prev, pageNumber: evt }));
  };

  const handleSearch = _debounce((event) => {
    dispatch(
      LoadWalletInstances({
        searchBy: event.target.value,
        pageNo: paging.pageNumber,
        pageSize: paging.pageSize,
      })
    );
    // cb(true);
  }, 1000);

  return (
    <div className="wallet-instance-container">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="search-container">
          <input
            type="search"
            placeholder="search"
            className="form-control"
            onChange={handleSearch}
          />
          <i className="ri-search-line"></i>
        </div>
        <div className="d-flex align-items-center">
          {/* <Button
            label={
              <span className="d-flex align-items-center">
                <i className="ri-filter-3-line me-2"></i>
                <span>Filter</span>
                <i className="ri-arrow-down-s-line ms-2"></i>
              </span>
            }
            background="neutral"
            size="md"
            className="me-2"
            style={{ background: "#eee", color: "#7E7E7E" }}
          /> */}
          <Button
            label="Configure"
            background="blue"
            type="button"
            size="md"
            onClick={() => history.push("configure")}
          />
        </div>
      </div>
      {loadingData ? (
        <Table variant="">
          <thead>
            <tr>
              <th width="15%">ID</th>
              <th width="25%">Name</th>
              <th width="25%">Wallet Type</th>
              <th width="15%">Currency</th>
              <th width="5%">Actions</th>
            </tr>
          </thead>
          <tbody style={{ borderTop: "none" }}>
            <LoadingTableContent colSpan={6} />
          </tbody>
        </Table>
      ) : (
        <>
          {data?.items?.length ? (
            <>
              <Table variant="unBounded">
                <thead>
                  <tr>
                    <th width="19%">Instance ID</th>
                    <th width="32%">Name</th>
                    <th width="29%">Wallet Type</th>
                    <th width="15%">Currency</th>
                    <th width="5%">Actions</th>
                  </tr>
                </thead>
                <tbody style={{ borderTop: "none" }}>
                  {data?.items?.map((app, idx) => (
                    <tr key={app.instanceId}>
                      <td
                        className="copy-id"
                        href="#"
                        id={`id${idx}`}
                        onMouseEnter={() => setTooltipId(`id${idx}`)}
                      >
                        <CopyToClipboard
                          text={app?.instanceId}
                          onCopy={() => {}}
                        >
                          <span>{app?.instanceId?.slice(0, 8)}***</span>
                        </CopyToClipboard>
                      </td>
                      {tooltipId === `id${idx}` && (
                        <Tooltip
                          placement="right"
                          isOpen={tooltipOpen}
                          target={`id${idx}`}
                          toggle={toggle}
                        >
                          Click to copy {app?.instanceId}
                        </Tooltip>
                      )}
                      <td>{app.name}</td>
                      <td>{app.walletType}</td>
                      <td>{app.currency}</td>
                      {/* <td>
                        {app.status ? Elipses("#3BA757") : Elipses("#FC4646")}&nbsp;&nbsp;
                        {app.status_text}
                      </td> */}
                      <td>
                        <div className="d-flex align-items-center justify-content-end">
                          <span className="me-4 d-flex align-items-center">
                            <Switch
                              id={app.instanceId}
                              checked={app.status}
                              name={app.instanceId}
                              onChange={(e) => setDataToSwitch(app)}
                            />
                          </span>
                          <span
                            className="me-4"
                            style={{ cursor: "pointer" }}
                            onClick={() => onEditIconClick(app)}
                          >
                            <i className="ri-pencil-line"></i>
                          </span>
                          <TableActionDropdown
                            data={app}
                            actions={tableActions}
                            horizontal={false}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}{" "}
                </tbody>
              </Table>
              {data?.items?.length ? (
                <div>
                  <PaginationWrapper>
                    <PaginationIndicator
                      currentPage={data?.pageNumber || 1}
                      totalPages={data?.pages || 1}
                    />
                    <div className="pagination-container">
                      <Pagination
                        className="pagination-bar"
                        currentPage={data?.pageNumber}
                        totalCount={data?.count || 1}
                        pageSize={paging?.pageSize}
                        onPageChange={(page) => onPageClick(page)}
                      />
                    </div>
                  </PaginationWrapper>
                </div>
              ) : null}
            </>
          ) : (
            <EmptyState history={history} />
          )}
        </>
      )}

      <ToggleStatusModal
        show={dataToSwitch}
        toggleModal={() => setDataToSwitch(null)}
        onSubmit={handleConfirmSwitch}
        processing={switchingStatus}
      />
    </div>
  );
};

export default WalletInstances;
